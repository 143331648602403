import { PropType } from 'vue';

export interface Channel {
  cid: string;
  corner_status: number;
  sign_status: number;
  channel: string;
  plat: number;
  splash_status: number;
  icon_status: number;
  id: number;
  type: number;
}
export const UseComProps = () => {
  return {
    channel: {
      type: Object as PropType<Channel>,
      required: true
    },
    gid: {
      type: [String, Number],
      default: '',
      required: true
    },
    commonInfo: {
      type: Object as PropType<any>,
      default: null
    }
  };
};
