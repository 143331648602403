import { TableOptions } from '@ultra/share/components/UTable/types/types';
// 打包状态
export const packStatusList = [
  { id: 1, name: '排队中' },
  { id: 2, name: '开始打包' },
  { id: 3, name: '打包失败' },
  { id: 4, name: '打包成功' }
];
// 状态列表
export const statusList = [
  { id: '', name: '不限' },
  { id: 1, name: '使用中' },
  { id: 0, name: '禁用中' }
];
// cdn状态列表
export const cdnStatusList = [
  { id: 0, name: '未发布' },
  { id: 1, name: '正在等待上传' },
  { id: 2, name: '上传中' },
  { id: 4, name: '上传成功' },
  { id: 3, name: '上传失败' },
  { id: 5, name: '定时发布中' }
];

// cdn 发布状态
export const cdnStatus: any = {
  3: { txt: '上传失败', color: 'danger' },
  0: { txt: '未发布', color: '' },
  1: { txt: '队列中', color: 'info' },
  2: { txt: '上传中', color: 'purple' },
  4: { txt: '上传成功', color: 'success' },
  5: { txt: '定时发布中', color: 'warning' }
};

export const columns: TableOptions[] = [
  { label: '项目ID', prop: 'projectId' },
  { label: '项目名称', prop: 'name' },
  { label: '分包来源', prop: 'originChannel' },
  { label: '投放状态', prop: 'status', slot: 'status' },
  { label: '分发渠道', prop: 'distributeName' },
  { label: '包体信息', prop: 'packageInfo', slot: 'packageInfo', width: '230px' },
  { label: '项目创建人', prop: 'creator' },
  { label: '打包状态', prop: 'pkgStatusText', slot: 'pkgStatusText' },
  { label: 'CDN链接', prop: 'cdnUrl', slot: 'cdnUrl', header: 'cdnUrlHeader', width: '120px' },
  { label: '操作', action: true }
];
