export const DICT_PLUG_TIPS: any = {
  bloc: { link: '', tips: '助手SDK插件资源' },
  vivoAd: { link: 'https://dev.vivo.com.cn/promotion/advertising', tips: 'VIVO的广告插件：整合vivo手机平台流量，提供个性化的营销方案，为广告主提供应用分发、网页推广、品牌营销等服务。' },
  oppoAd: { link: 'http://u.oppomobile.com/#/', tips: 'OPPO 推送平台：OPPO PUSH是ColorOS上的系统级通道。' },
  qihuAd: { link: 'https://ssp.360.cn/#firstPage', tips: '360广告插件' },
  toutiaoAd: { link: 'https://open.oceanengine.com/labels/7/docs/1696710649799695', tips: '头条广告sdk' },
  xiaomiAd: { link: 'https://e.mi.com/', tips: '小米消息推送服务在MIUI上为系统级通道，并且全平台通用。' },
  ucAd: { link: 'https://subnode.open.9game.cn/advert/page', tips: 'uc广告插件' },
  '4399Ad': { link: 'http://e.4399.cn/index.html', tips: '4399广告插件：4399广告平台是4399打造的效果广告平台,多种精细维度定向,覆盖4399海量玩家,帮您高效获取新增、平衡回收成本、实现推广目标。' },
  tencentAd: { link: 'https://e.qq.com/ads/ ', tips: '腾讯广告插件：腾讯拥有丰富的海量流量产品，覆盖用户各个使用场景，7×24 小时无缝触达您的目标客户' },
  jinritoutiaoAd: { link: 'https://ad.oceanengine.com/pages/login/index.html', tips: '今日头条广告插件：移动互联网超巨型流量入口,多样广告资源覆盖亿级活跃用户,一站式解决 本地商家推广、活动推广、APP下载、电话预约等营销诉求。' },
  gdtData: { link: 'https://wiki.open.qq.com/wiki/mobile/%E5%B9%BF%E7%82%B9%E9%80%9A', tips: '腾讯开放平台开发的广点通数据上报SDK，支持平台多个广告位上进行应用以及应用活动相关的精准推广。' },
  huaweiAdData: { link: 'https://developer.huawei.com/consumer/cn/huawei-ads/', tips: '华为广告插件：以华为终端高价值用户的体验为初心，打通华为1+8+N全场景生态布局下的媒介流量，为用户带来高品质的营销体验。' },
  sigmobAd: { link: 'https://www.sigmob.com/', tips: 'sigmobAd插件：Sigmob致力于通过大数据和智能算法帮助开发者变现效益最大化和推广效果最佳化。' },
  baiduData: { link: 'https://tongji.baidu.com/holmes/Analytics/%E5%88%86%E6%9E%90%E4%BA%91%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C/', tips: '百度数据行为上报插件，提供数据看板、行为分析、用户管理、商业转化等多个板块的高阶分析能力。' },
  oppoPush: { link: 'https://push.oppo.com/', tips: 'OPPO 推送平台：OPPO PUSH是ColorOS上的系统级通道。' },
  xiaomiPush: { link: 'https://dev.mi.com/console/appservice/push.html', tips: '小米消息推送服务在MIUI上为系统级通道，并且全平台通用。' },
  huaweiPush: { link: 'https://developer.huawei.com/consumer/cn/hms/huawei-pushkit', tips: '推送服务是华为提供的消息推送平台，建立了从云端到终端的消息推送通道。' },
  iosPush: { link: 'https://developer.apple.com/notifications/', tips: 'Ultra封装的苹果官方推送功能的插件，支持向苹果用户推送通知。' },
  kuaishouData: { link: '', tips: '快手数据插件' },
  gism: { link: 'https://yingxiao.uc.cn/', tips: 'UC数据插件' },
  marketData: { link: '', tips: '市场融合SDK' },
  floating: { link: '', tips: '包含客服、个人中心等功能的浮标' },
  share: { link: '', tips: '支持游戏内QQ、微信和新浪微博的分享' },
  toutiaoSub: { link: 'https://ad.oceanengine.com/pages/login/index.html', tips: '今日头条分包sdk插件:  头条分包专用' },
  dataAnalyse: { link: '', tips: '数据埋点' },
  admobAd: { link: 'https://admob.google.com/home/', tips: 'AdMob 通过应用内广告、切实可行的洞察力以及强大且易于使用的工具来发展您的应用业务，让您轻松赚取收入。' },
  facebookAd: { link: 'https://developers.facebook.com/docs/audience-network', tips: 'Meta Audience Network利用广告，实现移动资产变现。' },
  iOSMarketData: { link: '', tips: 'iOS市场插件，集成facebook、appsFlyer、adjust、firebase打点功能' }
};
