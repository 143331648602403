export default function UseConfig () {
  const internalLogin = [
    {
      type: 'qq',
      label: 'QQ',
      operateDesc: 'https://herogames.feishu.cn/wiki/Rwv2wM8koi4TGWkt47QcMYI5nKd#Jqqwdk0ScoOQwsxKkMsc7TEMnJd',
      params: [
        { label: 'appId', key: 'appId' },
        { label: 'appKey', key: 'appKey' },
        { label: '通用链接', key: 'universalLink', ios: 1 }
      ]
    },
    {
      type: 'wechat',
      label: '微信',
      operateDesc: 'https://herogames.feishu.cn/wiki/Rwv2wM8koi4TGWkt47QcMYI5nKd#EQCcdkwwgogSesxkJf3cpX8Pnvg',
      params: [
        { label: 'appId', key: 'appId' },
        { label: 'appSecret', key: 'appSecret' },
        { label: '通用链接', key: 'universalLink', ios: 1 }
      ]
    },
    {
      type: 'apple',
      label: '苹果',
      operateDesc: 'https://herogames.feishu.cn/wiki/Rwv2wM8koi4TGWkt47QcMYI5nKd#C6gId2auSoSowyx4YwpcaD7BngJ',
      params: [
        { label: 'appId', key: 'appId' },
        { label: 'appKey', key: 'appKey' },
        { label: 'appSecret', key: 'appSecret' }
      ]
    },
    {
      type: 'taptap',
      label: 'taptap',
      operateDesc: 'https://herogames.feishu.cn/wiki/Rwv2wM8koi4TGWkt47QcMYI5nKd#Sm4YdmcueoqQsyxUV9tckaM4nnc',
      params: [
        { label: 'Client ID', key: 'appId' }
      ]
    },
    {
      type: 'kuaibao',
      label: '好游快爆',
      params: [
        { label: 'gameId', key: 'appId' }
      ]
    },
    // { type: 'sina', label: '微博' },
    { type: 'phone', label: '短信验证码' },
    // { type: 'tourist', label: '游客登录' },
    { type: 'accLogin', label: '账号登录' },
    // { type: 'accReg', label: '账号注册' },
    // 隐藏设备快捷登录 20230529 @博川
    // { type: 'deviceQK', label: '设备快捷登录' },
    {
 type: 'phoneQK', label: '手机号一键登录',
      params: [
        { label: 'AppId', key: 'appId' },
        { label: 'AppKey', key: 'appKey' }
      ]
    }
  ];

  const internalLoginParamsChannel = ['qq', 'wechat', 'apple', 'taptap', 'kuaibao', 'phoneQK'];

  const internalPay = [
    {
      type: 15,
      label: '微信 APP',
      // icon: 'wechat',
      icon: 'wechat',
      params: [
        { label: '微信应用ID', key: 'wxpayAppAppId' },
        { label: '微信商户ID', key: 'wxpayAppPartner' },
        { label: 'APIv2密钥', key: 'wxpayAppAppKey' }
      ]
    },
    {
      type: 81,
      label: '微信 H5',
      icon: 'wechat',
      params: [
        { label: '支付主体', key: 'payMainPart' },
        { label: '微信商户ID', key: 'wxpayWebPartner' },
        { label: '微信应用ID', key: 'wxpayWebAppId' },
        { label: 'APIv2密钥', key: 'wxpayWebAppKey' },
        { label: '公众号私钥', key: 'wxpayWebMpAppSecret', tips: '可选，扫码分享用' },
        { label: '公众号APPID', key: 'wxpayWebMpAppId', tips: '可选，扫码分享用' }
      ]
    },
    {
      type: 0,
      label: '支付宝 APP',
      icon: 'alipay',
      params: [
        {
          label: '支付宝api版本',
          key: 'aliPayAppV2Key',
          options: [{ label: '1.0', value: '0' }, { label: '2.0', value: '1' }]
        },
        { label: '支付宝商户ID', key: 'alipayPartner' },
        { label: '支付宝商家账号', key: 'alipaySeller' },
        { label: '支付宝公钥', key: 'alipayPublicKey' },
        { label: '支付宝密钥', key: 'alipayPrivateKey' },
        { label: '支付宝V2APPID', key: 'aliV2PayAppid' },
        { label: '支付宝V2应用私钥', key: 'aliV2PayPrivateKey' },
        { label: '支付宝V2应用公钥', key: 'aliV2PayPublicKey' },
        { label: '支付宝V2公钥', key: 'aliV2PayAliPublicKey' }
      ]
    },
    {
      type: 88,
      label: '支付宝 H5',
      icon: 'alipay',
      params: [
        { label: '支付宝V2APPID', key: 'aliV2PayAppid' },
        { label: '支付宝V2应用私钥', key: 'aliV2PayPrivateKey' },
        { label: '支付宝V2应用公钥', key: 'aliV2PayPublicKey' },
        { label: '支付宝V2公钥', key: 'aliV2PayAliPublicKey' }
      ]
    },
    {
      type: 84,
      label: '花呗',
      icon: 'huab',
      params: [
        { label: '支付宝商户ID', key: 'alipayPartner' },
        { label: '支付宝商家账号', key: 'alipaySeller' },
        { label: '支付宝公钥', key: 'alipayPublicKey' },
        { label: '支付宝密钥', key: 'alipayPrivateKey' },
        { label: '支付宝V2APPID', key: 'aliV2PayAppid' },
        { label: '支付宝V2商家私钥', key: 'aliV2PayPrivateKey' },
        { label: '支付宝V2商家公钥', key: 'aliV2PayPublicKey' },
        { label: '支付宝V2公钥', key: 'aliV2PayAliPublicKey' }
      ]
    },
    {
      type: 83,
      label: 'QQ',
      icon: 'qq',
      params: [
        { label: 'QQ网页支付KEY', key: 'qqpayh5Key' },
        { label: 'QQ商户ID', key: 'qqpayH5Mchid' },
        { label: 'QQ网页支付SUBMCHID', key: 'qqpayH5Submchid' }
      ]
    },
    {
      type: 93,
      label: 'adapay-微信 APP',
      icon: 'wechat',
      tips: '三方聚合支付SDK（不需要可跳过），官网地址：https://www.adapay.tech/adapay/promote/',
      params: [
        { label: '应用ID', key: 'adaPayAppId' },
        { label: 'API_KEY(dev模式)', key: 'adaPayApiKey' },
        { label: 'API_KEY(prod模式)', key: 'adaPayApiKeyLive' },
        { label: '商户RSA私钥', key: 'adaPayPrivateKey' },
        { label: '商户RSA公钥', key: 'adaPayPublicKey' },
        { label: 'Adapay RSA 公钥', key: 'adaPayAdaPublicKey' },
        { label: '微信小程序APPID', key: 'adaPayWxLiteAppId' },
        { label: '微信小程序秘钥', key: 'adaPayWxLiteSecret' },
        { label: '微信小程序原始ID', key: 'adaPayWxLiteOriAppId' },
        { label: '微信APP应用ID', key: 'adaPayWxAppId' },
        { label: '微信小程序首页', key: 'adaPayWxLiteHomePath' },
        {
          label: '微信小程序版本',
          key: 'adaPayWxLiteType',
          options: [{ label: '正式', value: '0' }, { label: '开发', value: '1' }, { label: '预览', value: '2' }]
        }
      ]
    },
    {
      type: 89,
      label: 'adapay-支付宝 APP',
      icon: 'alipay',
      tips: '三方聚合支付SDK（不需要可跳过），官网地址：https://www.adapay.tech/adapay/promote/',
      params: [
        { label: '应用ID', key: 'adaPayAppId' },
        { label: 'API_KEY(dev模式)', key: 'adaPayApiKey' },
        { label: 'API_KEY(prod模式)', key: 'adaPayApiKeyLive' },
        { label: '商户RSA私钥', key: 'adaPayPrivateKey' },
        { label: '商户RSA公钥', key: 'adaPayPublicKey' },
        { label: 'Adapay RSA 公钥', key: 'adaPayAdaPublicKey' }
      ]
    },
    { type: 16, label: '苹果支付', icon: 'apple',params:[
        { label: 'key-id', key: 'applepayKeyId' },
        { label: 'api-key', key: 'applepayApiKey' },
        { label: 'issuer', key: 'applepayIssuer' },
        { label: 'Bundle ID', key: 'applepayBundleId' }
      ] },
    // { type: 80, label: '京东支付', icon: 'jdpay' },
    // { type: 17, label: '游娱玩', icon: 'joypay' },
    { type: 85, label: '代付', icon: 'dpay' },
    { type: 86, label: '扫码付', icon: 'scan' }

  ];
  const icons = {
    qq: require('@/assets/login/qq.png'),
    wechat: require('@/assets/login/wechat.png'),
    apple: require('@/assets/login/apple.png'),
    tourist: require('@/assets/login/tourist.png'),
    accLogin: require('@/assets/login/accLogin.png')
  };

  const loginIcon = {
    qq: icons.qq,
    wechat: icons.wechat,
    apple: icons.apple,
    taptap: require('@/assets/login/taptap.png'),
    phone: require('@/assets/login/phone.png'),
    sina: require('@/assets/login/sina.png'),
    tourist: icons.tourist,
    accLogin: icons.accLogin,
    accReg: require('@/assets/login/accReg.png'),
    deviceQK: require('@/assets/login/deviceQK.png'),
    phoneQK: require('@/assets/login/phoneQK.png'),
    kuaibao: require('@/assets/login/kuaibao.png')
  };
  const payIcon = {
    qq: icons.qq,
    wechat: require('@/assets/pay/wechat.png'),
    wechatNet: icons.wechat,
    alipay: require('@/assets/pay/alipay.png'),
    apple: icons.apple,
    jdpay: require('@/assets/pay/jdpay.png'),
    joypay: require('@/assets/pay/joypay.png'),
    huab: require('@/assets/pay/huab.png'),
    dpay: require('@/assets/pay/dpay.png'),
    scan: require('@/assets/pay/scan.png')
  };

  const globalLogin = [
    {
      label: 'Twitter',
      key: 'twitter',
      type: 9,
      operateDesc: 'https://herogames.feishu.cn/wiki/ZAZ3wosHNi2NHLkrVWOcsoxmnvh#J1t0d2ef8oo5gTxS9Ffcm48BnCb'
    },
    {
      label: 'Apple',
      key: 'apple',
      type: 10,
      operateDesc: 'https://herogames.feishu.cn/wiki/Rwv2wM8koi4TGWkt47QcMYI5nKd#C6gId2auSoSowyx4YwpcaD7BngJ'
    },
    { label: 'Line', key: 'line', type: 12 },
    { label: 'OPPO', key: 'oppo', type: 20 },
    {
      label: 'Facebook',
      key: 'facebook',
      type: 5,
      operateDesc: 'https://herogames.feishu.cn/wiki/ZAZ3wosHNi2NHLkrVWOcsoxmnvh#AOgcd8U6Oomws6xwJCOcYZXgnUh'
    },
    {
      label: 'Google',
      key: 'google',
      type: 6,
      operateDesc: 'https://herogames.feishu.cn/wiki/ZAZ3wosHNi2NHLkrVWOcsoxmnvh#KU2MdgsuaomgCmxwte3cy2U7nPb'
    },
    {
      label: 'GameCenter',
      key: 'gc',
      type: 7,
      operateDesc: 'https://herogames.feishu.cn/wiki/ZAZ3wosHNi2NHLkrVWOcsoxmnvh#VpqRdfjLmo4h3QxHMU2c5vpJnnd'
    },
    { label: '华为', key: 'huawei', type: 19 },
    { label: '游客登录', key: 'tourist', type: 4 },
    { label: '邮箱账密登录', key: 'email', type: 16 }
    // { label: '账号登录', key: 'account', type: 11 },
    // { label: '三方迁移', key: 'ext', type: 18 },
    // { label: '无界的快速登录', key: 'tourist1', type: 22 }
  ];

  const globalLoginIcon = {
    twitter: require('@/assets/login/twitter.png'),
    facebook: require('@/assets/login/facebook.png'),
    apple: icons.apple,
    line: require('@/assets/login/line.png'),
    google: require('@/assets/login/google.png'),
    gc: require('@/assets/login/gameCenter.png'),
    huawei: require('@/assets/login/huawei.png'),
    oppo: require('@/assets/login/oppo.png'),
    tourist: icons.tourist,
    tourist1: icons.tourist,
    account: icons.accLogin,
    ext: require('@/assets/login/ext.png'),
    email: require('@/assets/login/email.png')
  };

  const globalPay = [
    { key: 1, value: 'AppStore' },
    { key: 2, value: 'GooglePlay' },
    { key: 13, value: 'OneStore' },
    { key: 11, value: '华为钱包' },
    { key: 12, value: 'vivo支付' },
    { key: 14, value: 'OPPO' },
    { key: 15, value: 'Cafe Bazaar' },
    { key: 3, value: 'MYCARD_線上儲值_中華支付行動電話帳單' },
    { key: 4, value: 'MYCARD_線上儲值_中華支付市內電話帳單' },
    { key: 5, value: 'MYCARD_線上儲值_台灣大哥大電信' },
    { key: 6, value: 'MYCARD_線上儲值_亞太電信' },
    { key: 7, value: 'MYCARD_線上儲值_台灣之星' },
    { key: 8, value: 'MYCARD_線上儲值_遠傳電信' },
    { key: 9, value: 'MYCARD_會員扣點' },
    { key: 10, value: 'MYCARD_點卡儲值' },
    { key: 17, value: 'XSOLLA商城' },
    { key: 18, value: 'XSOLLA Payment' },
    { key: 19, value: 'Payssion' },
    { key: 21, value: 'Airwallex' },
    { key: 27, value: 'Catappult' },
    { key: 28, value: 'Paypal' },
    { key: 30, value: 'PayerMax' },
    { key: 31, value: 'PayerMaxShop' },
  ];
  const myCardIcon = require('@/assets/pay/mycard.png');
  const globalPayIcon = {
    1: icons.apple,
    2: require('@/assets/pay/google.png'),
    3: myCardIcon,
    4: myCardIcon,
    5: myCardIcon,
    6: myCardIcon,
    7: myCardIcon,
    8: myCardIcon,
    9: myCardIcon,
    10: myCardIcon,
    11: require('@/assets/pay/huawei.png'),
    12: require('@/assets/pay/vivo.png'),
    13: require('@/assets/pay/oneStore.png'),
    14: require('@/assets/pay/oppo.png'),
    15: require('@/assets/pay/midEast.png'),
    17: require('@/assets/pay/xsolla.png'),
    18: require('@/assets/pay/xsolla.png'),
    19: require('@/assets/pay/payssion.png'),
    21: require('@/assets/pay/airwallex.png'),
    27: require('@/assets/pay/catappult-icon.png'),
    28: require('@/assets/pay/paypal.png'),
    30: require('@/assets/pay/paypal.png'),
    31: require('@/assets/pay/paypal.png'),
  };

  const GlobalLoginConfigDict: any = {
    line: {
      configKeys: [
        {
          label: 'Channel ID',
          key: 'appKey',
          length: 10,
          html: '<p>Channel ID获取来源：</p><ul><li>Line后台地址：https://developers.line.biz/en/</li><li>Line后台登录账号：使用工作室通用账号或者自行注册，视自己情况而定。</li><li>参数位置：登录Line后台-&gt;Providers-&gt;创建或者找到您的应用并点击该应用进入详情页即可见参数。</li></ul><p><img src="https://s-static.yingxiong.com/file/1626337838381_58343340.png"></p>'
        },
        {
          label: 'Channel secret',
          key: 'appSecret',
          length: 32,
          html: '<p>Channel secret获取来源：</p><ul><li>Line后台地址：https://developers.line.biz/en/</li><li>Line后台登录账号：使用工作室通用账号或者自行注册，视自己情况而定。</li><li>参数位置：登录Line后台-&gt;Providers-&gt;创建或者找到您的应用并点击该应用进入详情页即可见参数。</li></ul><p><img src="https://s-static.yingxiong.com/file/1626337891553_78600897.png"></p>'
        },
        { label: '包名', key: 'pkgName', tips: '配置当前渠道包名（与发行渠道配置中该渠道所配置包名一致）' }
      ]
    },
    oppo: {
      configKeys: [
        {
          label: 'appKey',
          key: 'appKey',
          length: 32,
          html: '<p>oppo appkey获取来源如下：</p><ul><li>后台地址：https://open.oppomobile.com/</li><li>后台登录账号：使用工作室通用账号或者自行注册，视自己情况而定。</li><li>参数位置：在管理中心-&gt;应用服务平台-&gt;创建或找到您需要的游戏点击进入详情-&gt;即可见appkey</li></ul><p><img src="https://s-static.yingxiong.com/file/1626338014795_50443741.png"></p>'
        },
        {
          label: 'appsecret',
          key: 'appSecret',
          length: 32,
          html: '<p>oppo appsecret获取来源如下：</p><ul><li>后台地址：https://open.oppomobile.com/</li><li>后台登录账号：使用工作室通用账号或者自行注册，视自己情况而定。</li><li>参数位置：在管理中心-&gt;应用服务平台-&gt;创建或找到您需要的游戏点击进入详情-&gt;<span style="color: rgb(51, 51, 51);">即可见appsecret</span></li></ul><p><img src="https://s-static.yingxiong.com/file/1626338014795_50443741.png"></p>'
        },
        {
          label: '包名',
          key: 'pkgName',
          html: '<p>包名 获取来源如下：</p><ul><li>后台地址：https://open.oppomobile.com/</li><li>后台登录账号：使用工作室通用账号或者自行注册，视自己情况而定。</li><li>参数位置：在管理中心-&gt;应用服务平台-&gt;创建或找到您需要的游戏点击进入详情-&gt;<span style="color: rgb(51, 51, 51);">即可见包名</span></li></ul><p><img src="https://s-static.yingxiong.com/file/1626338014795_50443741.png"></p>'
        }
      ]
    },
    twitter: {
      configKeys: [
        {
          label: 'API Key',
          key: 'appKey',
          length: 25,
          html: '<p>API Key获取来源：</p><ul><li>Twitter后台地址：https://developer.twitter.com/en</li><li>Twitter后台登录账号：使用工作室通用账号或者自行注册，视自己情况而定。</li><li>参数位置：登录Twitter后台-&gt;Projects &amp; Apps-&gt;点击具体游戏即可看见API Key。（注：API Key根据Twitter后台的规则只在第一次创建游戏时外显，如您未能保存第一次显示的参数值，则可以根据提示通过Regenerate再生功能获取，获取后需要保证所有使用该参数的地方都得到更新）</li></ul><p><img src="https://s-static.yingxiong.com/file/1626338213792_45323588.png"></p>'
        },
        {
          label: 'API Secret',
          key: 'appSecret',
          length: 50,
          html: '<p>API Secret获取来源：</p><ul><li>Twitter后台地址：https://developer.twitter.com/en</li><li>Twitter后台登录账号：使用工作室通用账号或者自行注册，视自己情况而定。</li><li>参数位置：登录Twitter后台-&gt;Projects &amp; Apps-&gt;点击具体游戏即可看见API Secret。（注：API Secret根据Twitter后台的规则只在第一次创建游戏时外显，如您未能保存第一次显示的参数值，则可以根据提示通过Regenerate再生功能获取，获取后需要保证所有使用该参数的地方都得到更新）</li></ul><p><img src="https://s-static.yingxiong.com/file/1626338213792_45323588.png"></p>'
        },
        { label: '包名', key: 'pkgName', tips: '配置当前渠道包名（与发行渠道配置中该渠道所配置包名一致）' }
      ]
    },
    apple: {
      configKeys: [
        {
          label: 'AppId',
          key: 'appId',
          length: 10,
          html: '<p>AppID获取来源：</p><ul><li>苹果开发者后台地址：https://developer.apple.com/</li><li>后台登录账号：使用工作室通用账号或者自行注册，视自己情况而定。</li><li>参数位置：登录Apple开发者后台-&gt;Account-&gt;Certificates,IDs &amp; Profiles-&gt;keys-&gt;新增或者找到你已经创建的游戏点击进入详情即可看见AppID</li></ul><p><img src="https://s-static.yingxiong.com/file/1626338392515_10527.png"></p><p><img src="https://s-static.yingxiong.com/file/1626338402765_88031825.png"></p><p><img src="https://s-static.yingxiong.com/file/1626338412200_86658099.png"></p>'
        },
        {
          label: 'Key ID',
          key: 'appKey',
          length: 10,
          html: '<p>Key ID获取来源：</p><ul><li>苹果开发者后台地址：https://developer.apple.com/</li><li>后台登录账号：使用工作室通用账号或者自行注册，视自己情况而定。</li><li>参数位置：登录Apple开发者后台-&gt;Account-&gt;Certificates,IDs &amp; Profiles-&gt;keys-&gt;新增或者找到你已经创建的游戏点击进入详情即可看见KeyID</li></ul><p><img src="https://s-static.yingxiong.com/file/1626338511883_76593796.png"><img src="https://s-static.yingxiong.com/file/1626338509861_4866742.png"></p><p><img src="https://s-static.yingxiong.com/file/1626338522446_7104346.png"></p>'
        },
        {
          label: 'AppSecret',
          key: 'appSecret',
          length: 200,
          html: '<p>私有密钥获取来源：</p><ul><li>苹果开发者后台地址：https://developer.apple.com/</li><li>后台登录账号：使用工作室通用账号或者自行注册，视自己情况而定。</li><li>参数位置：登录Apple开发者后台-&gt;Account-&gt;Certificates,IDs &amp; Profiles-&gt;keys-&gt;新增或者找到你已经创建的游戏点击进入详情-&gt;Download文件格式为P8的文件打开后即可见私有密钥（P8文件只可下载一次，请下载后保存好）</li></ul><p><br></p><p><img src="https://s-static.yingxiong.com/file/1626338584458_32671969.png"></p><p><img src="https://s-static.yingxiong.com/file/1626338622708_56385937.png"></p><p><img src="https://s-static.yingxiong.com/file/1626338630838_12049693.png"></p>'
        },
        {
          label: '包名',
          key: 'pkgName',
          html: '<p>包名获取来源：</p><ul><li>苹果开发者后台地址：https://developer.apple.com/</li><li>后台登录账号：使用工作室通用账号或者自行注册，视自己情况而定。</li><li>参数位置：登录Apple开发者后台-&gt;Account-&gt;Certificates,IDs &amp; Profiles-&gt;keys-&gt;新增或者找到你已经创建的游戏点击进入详情即可见包名</li></ul><p><br></p><p><img src="https://s-static.yingxiong.com/file/1626338584458_32671969.png"></p><p><img src="https://s-static.yingxiong.com/file/1626338622708_56385937.png"></p><p><img src="https://s-static.yingxiong.com/file/1626338725849_21343311.png"></p>'
        }
      ]
    }
    // tourist: {
    //   configKeys: [
    //     { label: '绑定频率', key: 'hg.guest.showbind.count', tips: '-1 不弹,0 每次都弹 ,1 间隔1次弹,2 间隔2次弹,3 间隔3次弹,4 间隔4次弹,5 间隔5次弹' }
    //   ]
    // }
  };

  GlobalLoginConfigDict[9] = GlobalLoginConfigDict.twitter;
  GlobalLoginConfigDict[10] = GlobalLoginConfigDict.apple;
  GlobalLoginConfigDict[12] = GlobalLoginConfigDict.line;
  GlobalLoginConfigDict[20] = GlobalLoginConfigDict.oppo;
  // GlobalLoginConfigDict[4] = GlobalLoginConfigDict.tourist;

  const GlobalPayConfigDict: any = {
    Google: {
      configKeys: [
        {
          label: '公钥',
          key: 'appSecret',
          length: 392,
          html: '<p>公钥获取来源：</p><ul><li>Google开发者后台地址：https://play.google.com/apps/publish</li><li>后台登录账号：使用工作室通用账号或者自行注册，视自己情况而定。</li><li>参数位置：登录Google开发者后台-&gt;所有应用-&gt;新增或者找到你已经创建的应用点击进入详情-&gt;在创收设置（港版或者英文版平台可能该菜单会</li></ul><p><br></p><p><img src="https://s-static.yingxiong.com/file/1626406681486_99177230.png"><img src="https://s-static.yingxiong.com/file/1626406689911_88865742.png"></p>'
        },
        { label: '包名', key: 'pkgName', tips: '配置当前渠道包名（与发行渠道配置中该渠道所配置包名一致）' },
        {
          label: '订单查询账号',
          notRequired: true,
          key: 'orderQueryId',
          html: '<p><span style="color: rgb(217, 0, 27);">订单查询账号作用：SDK用来查询谷歌后台退款订单时必配、游戏需要使用谷歌订阅功能时必配。如您无需上述功能则不需要配置。</span></p><p>订单查询账号获取来源：</p><ul><li>Google开发者后台地址：https://play.google.com/apps/publish</li><li>后台登录账号：使用工作室通用账号或者自行注册，视自己情况而定。</li><li>参数位置：登录Google开发者后台-&gt;设置-&gt;API权限-&gt;服务账号即可见订单查询账号<img src="https://s-static.yingxiong.com/file/1626406784196_60611642.png"></li></ul>'
        },
        {
          label: '查询私钥(P12)',
          notRequired: true,
          key: 'p12',
          // length: 3308,
          html: '<p><span style="color: rgb(217, 0, 27);">查询私钥P12作用：SDK用来查询谷歌后台退款订单时必配、游戏需要使用谷歌订阅功能时必配。如您无需上述功能则不需要配置。</span></p><p>查询私钥P12获取来源：</p><ul><li>Google后台地址：https://console.developers.google.com</li><li>后台登录账号：使用工作室通用账号或者自行注册，视自己情况而定。</li><li>参数位置：Google Cloud Platform-&gt;服务账号-&gt;创建或找到你的服务账号，点击进入详情页-&gt;密钥-&gt;添加密钥-&gt;选择P12即可（创建后P12文件会自动下载，请妥善保存，仅可下载一次）</li></ul><p><img src="https://s-static.yingxiong.com/file/1635759951896_20926003.png"></p><p><img src="https://s-static.yingxiong.com/file/1635759991867_46995717.png"></p>'
        },
        // {
        //   label: '谷歌订阅回调',
        //   notRequired: true,
        //   key: 'productId',
        //   tips: '如需使用谷歌订阅功能，请将该订阅回调地址配置到谷歌后台。'
        // },
        {
          label: '谷歌订阅回调',
          notRequired: true,
          key: 'callbackUrl',
          type: 'copy',
          tips: '如需使用谷歌订阅功能，请将该订阅回调地址配置到谷歌后台。'
        }
      ]
    },
    OneStore: {
      configKeys: [
        {
          label: 'Client ID',
          key: 'appId',
          minLength: 1,
          maxLength: 512,
          html: '<p>Client ID参数获取来源</p><ul><li>后台地址：https://dev.onestore.co.kr/</li><li>后台登录账号：使用工作室通用账号或者自行注册，视自己情况而定。</li><li>参数位置：在APPS-&gt;Applications中-&gt;创建或找到您需要的游戏点击进入详情-&gt;In-App-&gt;Managed In-APP 即可见参数</li></ul><p><img src="https://s-static.yingxiong.com/file/1626406900814_50804970.png"></p>'
        },
        {
          label: 'Client Secret',
          key: 'appSecret',
          length: 44,
          html: '<p>Client Secret参数获取来源</p><ul><li>后台地址：https://dev.onestore.co.kr/</li><li>后台登录账号：使用工作室通用账号或者自行注册，视自己情况而定。</li><li>参数位置：在APPS-&gt;Applications中-&gt;创建或找到您需要的游戏点击进入详情-&gt;In-App-&gt;Managed In-APP即可见参数</li></ul><p><img src="https://s-static.yingxiong.com/file/1626406939347_34598812.png"></p>'
        },
        {
          label: '包名',
          key: 'pkgName',
          html: '<p>包名参数获取来源</p><ul><li>后台地址：https://dev.onestore.co.kr/</li><li>后台登录账号：使用工作室通用账号或者自行注册，视自己情况而定。</li><li>参数位置：在APPS-&gt;Applications中-&gt;创建或找到您需要的游戏点击进入详情-&gt;Settings for Licensing即可见包名</li></ul><p><img src="https://s-static.yingxiong.com/file/1626406974060_79789276.png"></p>'
        },
        {
          label: 'License Key',
          key: 'publicKey',
          length: 216,
          html: '<p>License Key参数获取来源</p><ul><li>后台地址：https://dev.onestore.co.kr/</li><li>后台登录账号：使用工作室通用账号或者自行注册，视自己情况而定。</li><li>参数位置：在APPS-&gt;Applications中-&gt;创建或找到您需要的游戏点击进入详情-&gt;In-App-&gt;Managed In-APP即可见参数</li></ul><p><img src="https://s-static.yingxiong.com/file/1626407351240_15825316.png"></p>'
        }
      ]
    },
    Apple: {
      configKeys: [
        {
          label: '共享密钥',
          notRequired: true,
          key: 'appSecret',
          length: 32,
          html: '<p>共享密钥获取来源：</p><ul><li>苹果开发者后台地址：https://developer.apple.com/</li><li>后台登录账号：使用工作室通用账号或者自行注册，视自己情况而定。</li><li>参数位置：登录Apple开发者后台-&gt;APP Store Connect-&gt;我的App中新增或者找到你已经创建的游戏点击进入详情-&gt;App内购买项目-&gt;管理-&gt;App专用共享密钥<span style="color: rgb(217, 0, 27);">（共享密钥非必须设置，但是一旦在苹果后台设置了共享密钥则必须将该密钥配置到中台</span></li></ul><p><span style="color: rgb(217, 0, 27);">﻿</span><img src="https://s-static.yingxiong.com/file/1626681757679_52977691.png"></p>'
        },
        { label: '包名', key: 'pkgName', tips: '配置当前渠道包名（与发行渠道配置中该渠道所配置包名一致）' },
        // {
        //   label: '苹果退款/订阅回调',
        //   notRequired: true,
        //   key: 'productId',
        //   tips: '请将该退款地址配置到苹果后台，保证我们可以成功接收退款数据'
        // },
        {
          label: '苹果退款/订阅回调',
          notRequired: true,
          key: 'callbackUrl',
          type: 'copy',
          tips: '请将该退款地址配置到苹果后台，保证我们可以成功接收退款数据'
        }
      ]
    },
    HuaWei: {
      configKeys: [
        {
          label: 'APP ID',
          key: 'appId',
          length: 9,
          html: '<p>参数获取来源如下：</p><ul><li>后台地址：https://developer.huawei.com/consumer/cn/console#/serviceCards/</li><li>后台登录账号：使用工作室通用账号或者自行注册，视自己情况而定。</li><li>参数位置：在管理中心-&gt;应用服务-&gt;开发服务-&gt;支付-&gt;创建或找到您需要的游戏点击进入详情即可找到华为支付相关参数</li></ul><p><img src="https://s-static.yingxiong.com/file/1626407474920_99887823.png"></p><p><img src="https://s-static.yingxiong.com/file/1626407483822_77649095.png"></p>'
        },
        {
          label: 'APP SECRET',
          key: 'appSecret',
          length: 64,
          html: '<p>参数获取来源如下：</p><ul><li>后台地址：https://developer.huawei.com/consumer/cn/console#/serviceCards/</li><li>后台登录账号：使用工作室通用账号或者自行注册，视自己情况而定。</li><li>参数位置：在管理中心-&gt;应用服务-&gt;开发服务-&gt;支付-&gt;创建或找到您需要的游戏点击进入详情即可找到华为支付相关参数</li></ul><p><img src="https://s-static.yingxiong.com/file/1626407474920_99887823.png"></p><p><img src="https://s-static.yingxiong.com/file/1626407483822_77649095.png"></p>'
        },
        {
          label: '包名',
          key: 'pkgName',
          tips: '<p>参数获取来源如下：</p><ul><li>后台地址：https://developer.huawei.com/consumer/cn/console#/serviceCards/</li><li>后台登录账号：使用工作室通用账号或者自行注册，视自己情况而定。</li><li>参数位置：在管理中心-&gt;应用服务-&gt;开发服务-&gt;支付-&gt;创建或找到您需要的游戏点击进入详情即可找到华为支付相关参数</li></ul><p><img src="https://s-static.yingxiong.com/file/1626407474920_99887823.png"></p><p><img src="https://s-static.yingxiong.com/file/1626407483822_77649095.png"></p>'
        },
        {
          label: '支付公钥',
          key: 'publicKey',
          length: 564,
          html: '<p>参数获取来源如下：</p><ul><li>后台地址：https://developer.huawei.com/consumer/cn/console#/serviceCards/</li><li>后台登录账号：使用工作室通用账号或者自行注册，视自己情况而定。</li><li>参数位置：在管理中心-&gt;应用服务-&gt;开发服务-&gt;支付-&gt;创建或找到您需要的游戏点击进入详情即可找到华为支付相关参数</li></ul><p><img src="https://s-static.yingxiong.com/file/1626407474920_99887823.png"></p><p><img src="https://s-static.yingxiong.com/file/1626407483822_77649095.png"></p>'
        }
      ]
    },
    OPPO: {
      configKeys: [
        {
          label: '包名',
          key: 'pkgName',
          html: '<p>包名获取来源</p><ul><li>后台地址：https://open.oppomobile.com/</li><li>后台登录账号：使用工作室通用账号或者自行注册，视自己情况而定。</li><li>参数位置：在管理中心-&gt;应用服务平台-&gt;创建或找到您需要的游戏点击进入详情-&gt;即可见包名、appsecret参数</li></ul><p><img src="https://s-static.yingxiong.com/file/1626407718300_1371864.png"></p>'
        },
        {
          label: 'appsecret',
          key: 'publicKey',
          length: 216,
          html: '<p>参数获取来源</p><ul><li>目前appsecret由OPPO商务在提供oppo接入SDK时一并提供改参数，且海外游戏通用该支付参数</li></ul><p><img src="https://s-static.yingxiong.com/file/1626681937636_43451470.png"></p>'
        }
      ]
    },
    Vivo: {
      configKeys: [
        {
          label: 'appId',
          key: 'appId',
          length: 19,
          html: '<p>参数获取来源</p><ul><li>后台地址：https://dev.vivo.com.cn/</li><li>后台登录账号：使用工作室通用账号或者自行注册，视自己情况而定。</li><li>参数位置：在管理中心-&gt;应用与游戏-&gt;创建或找到您需要的游戏点击进入详情-&gt;即可见包名、CP-ID、支付APP-Key等参数</li></ul><p><img src="https://s-static.yingxiong.com/file/1626407780620_80362032.png"></p>'
        },
        {
          label: 'App-Key',
          key: 'appSecret',
          // length: 32,
          html: '<p>参数获取来源</p><ul><li>后台地址：https://dev.vivo.com.cn/</li><li>后台登录账号：使用工作室通用账号或者自行注册，视自己情况而定。</li><li>参数位置：在管理中心-&gt;应用与游戏-&gt;创建或找到您需要的游戏点击进入详情-&gt;即可见包名、CP-ID、支付APP-Key等参数</li></ul><p><img src="https://s-static.yingxiong.com/file/1626407780620_80362032.png"></p>'
        },
        {
          label: '包名',
          key: 'pkgName',
          html: '<p>参数获取来源</p><ul><li>后台地址：https://dev.vivo.com.cn/</li><li>后台登录账号：使用工作室通用账号或者自行注册，视自己情况而定。</li><li>参数位置：在管理中心-&gt;应用与游戏-&gt;创建或找到您需要的游戏点击进入详情-&gt;即可见包名、CP-ID、支付APP-Key等参数</li></ul><p><img src="https://s-static.yingxiong.com/file/1626407780620_80362032.png"></p>'
        }
      ]
    },
    MidEast: {
      configKeys: [
        { label: 'AppId', key: 'appId' },
        { label: 'AppSecret', key: 'appSecret' },
        { label: 'PublicKey', key: 'publicKey' },
        { label: '包名', key: 'pkgName' },
        { label: '授权的Token', key: 'orderQueryId', notRequired: true },
        {
          label: '确认链接',
          key: 'p12',
          notRequired: true,
          render: ({ p12 }: {
            p12: string
          }) => `<a style='word-break: break-all;' href='${p12}' target='_blank'>${p12 || '暂无链接地址'}<a/>`,
          tips: '参数配置完成后，需前往此页面确认后，支付方式方可生效。'
        }
      ]
    },
    XSOLLAPayment: {
      configKeys: [
        {
          label: 'API 密钥',
          key: 'apiSecret',
          html: '<p><img src="https://s-static.yingxiong.com/file/1665385774022_42193553.png"></p>'
        },
        {
          label: '项目密钥',
          key: 'appSecret',
          html: '<p><img src="https://s-static.yingxiong.com/file/1665385818334_47886656.png"></p>'
        },
        {
          label: '项目ID',
          key: 'appId',
          html: '<p><img src="https://s-static.yingxiong.com/file/1665385865046_4075652.png"></p>'
        },
        {
          label: '重定向地址',
          notRequired: true,
          key: 'redirectUrl',
          html: '<p><img src="https://s-static.yingxiong.com/file/1665385903056_16421458.png"></p>'
        },
        {
          label: '沙盒模式',
          notRequired: true,
          key: 'sandbox',
          options: [{ label: '沙盒环境', value: 1 }, { label: '正式环境', value: 0 }],
          tips: '默认是正式环境'
        },
        {
          label: '商户ID',
          notRequired: true,
          key: 'shopManagerId'
        },
        {
          label: '支持external id',
          notRequired: true,
          key: 'supportExId',
          options: [{ label: '是', value: 1 }, { label: '否', value: 0 }],
        },{
          label: '管理员邮箱',
          key: 'iamEmail',
          tips: '在沙盒模式中使用，xsolla pa后台-公司设置-用户目录里面的email'
        },
        {
          label: '是否跳转外部浏览器',
          key: 'supportLinkExt',
          options: [
            { label: '是', value: 1 },
            { label: '否', value: 0 }
          ],
          tips: '默认不跳转'
        },
        {
          label: 'XSOLLA支付回调',
          notRequired: true,
          key: 'callbackUrl',
          type: 'copy'
          // tips: '如需使用谷歌订阅功能，请将该订阅回调地址配置到谷歌后台。'
        }
      ]
    },
    XSOLLA: {
      tips: 'Xsolla提供的商城功能，官网地址：https://developers.xsolla.com/zh/doc/in-game-store/',
      configKeys: [
        {
          label: 'API 密钥',
          key: 'apiSecret',
          html: '<p><img src="https://s-static.yingxiong.com/file/1665385774022_42193553.png"></p>'
        },
        {
          label: '项目密钥',
          key: 'appSecret',
          html: '<p><img src="https://s-static.yingxiong.com/file/1665385818334_47886656.png"></p>'
        },
        {
          label: '项目ID',
          key: 'appId',
          html: '<p><img src="https://s-static.yingxiong.com/file/1665385865046_4075652.png"></p>'
        },
        {
          label: '公司ID',
          key: 'p12',
          html: '<p><img src="https://cdn.ultrasdk.com/file/1666082364131_32470193.png"></p>'
        },
        {
          label: '商城主页连接',
          key: 'shopHomeUrl',
          html: '<p><img src="https://s-static.yingxiong.com/file/1665385609814_35384343.png"></p>'
        },
        { label: '登录令牌获取地址', key: 'shopTokenUrl', tips: '默认为：https://login.xsolla.com/api/oauth2/token' },
        {
          label: '登录服务器密钥',
          key: 'privateKey',
          html: '<p><img src="https://s-static.yingxiong.com/file/1665386057317_10255881.png"></p>'
        },
        {
          label: '登录服务器密钥ID',
          key: 'publicKey',
          html: '<p><img src="https://s-static.yingxiong.com/file/1665386057317_10255881.png"></p>'
        },
        {
          label: '登录管理器ID',
          key: 'shopManagerId',
          html: '<p><img src="https://s-static.yingxiong.com/file/1665386198505_32148451.png"></p>'
        },
        {
          label: '登录管理器URL',
          key: 'shopManagerUrl',
          tips: '默认填写：https://login.xsolla.com/api/users/login/server_custom_id'
        },
        {
          label: '向游戏服务商下单接口',
          key: 'shopOrderUrl',
          tips: '此为cp方需自行实现的接口。sdk将通过此接口向cp方下单。具体接入方式请联系UltraSDK运营人员'
        }
      ]
    },
    Mycard10: {
      configKeys: [
        { label: 'APP ID', key: 'appId' },
        { label: 'APP SECRET', key: 'appSecret' }
      ]
    },
    Catappult: {
      configKeys: [
        { label: 'pkgName', key: 'pkgName' },
        { label: 'appSecret', key: 'appSecret' },
      ],
    },
    Payssion: {
      configKeys: [
        { label: 'api_key', key: 'appId' },
        { label: 'secret_key', key: 'appSecret' },
        { label: '支付方式(pm_id)', key: 'p12' },
        // { label: '重定向地址', key: 'redirectUrl' },
        {
          label: '沙盒模式',
          key: 'sandbox',
          options: [{ label: '沙盒环境', value: 1 }, { label: '正式环境', value: 0 }],
          tips: '默认是正式环境'
        },
        {
          label: '支付回调',
          notRequired: true,
          key: 'callbackUrl',
          type: 'copy'
          // tips: '如需使用谷歌订阅功能，请将该订阅回调地址配置到谷歌后台。'
        }
      ]
    },
    Airwallex: {
      configKeys: [
        {
          label: 'api_key',
          key: 'appId',
          html: '<p><img src="https://s-static.yingxiong.com/file/help-20230519-151700.png"></p>'
        },
        {
          label: 'secret_key',
          key: 'appSecret',
          html: '<p><img src="https://s-static.yingxiong.com/file/help-20230519-151655.png"></p>'
        },
        {
          label: 'webhook key',
          key: 'privateKey',
          html: '<p><img src="https://s-static.yingxiong.com/file/help-20230519-151635.png"></p>'
        },
        {
          label: '是否跳转外部浏览器',
          key: 'supportLinkExt',
          options: [
            { label: '是', value: 1 },
            { label: '否', value: 0 }
          ],
          tips: '默认不跳转'
        },
        {
          label: '沙盒模式',
          key: 'sandbox',
          options: [{ label: '沙盒环境', value: 1 }, { label: '正式环境', value: 0 }],
          tips: '默认是沙盒环境'
        },
        {
          label: '支付回调',
          notRequired: true,
          key: 'callbackUrl',
          type: 'copy'
          // tips: '如需使用谷歌订阅功能，请将该订阅回调地址配置到谷歌后台。'
        }
      ]
    },
    Paypal: {
      configKeys: [
        { label: '客户端ID', key: 'appId' },
        { label: '客户端秘钥', key: 'appSecret' },
        { label: '沙盒模式', key: 'sandbox', options: [{ label: '沙盒环境', value: 1 }, { label: '正式环境', value: 0 }], tips: '默认是开启' },
      ]
    },
    PayerMax:{
      configKeys: [
        { label: 'APPID', key: 'appId' },
        { label: '商户号', key: 'merchantId' },
        { label: '商家私钥(正式)', key: 'privateKey' },
        { label: '平台公钥(正式)', key: 'publicKey' },
        { label: '商家私钥(沙盒)', key: 'mchPrivateSandbox' },
        { label: '平台公钥(沙盒)', key: 'platPublicSandbox' },
        { label: '沙盒模式', key: 'sandbox', options: [{ label: '沙盒环境', value: 1 }, { label: '正式环境', value: 0 }], tips: '默认是开启' },
        {
          label: '支付回调',
          notRequired: true,
          key: 'callbackUrl',
          type: 'copy'
          // tips: '如需使用谷歌订阅功能，请将该订阅回调地址配置到谷歌后台。'
        }
      ]
    },
    PayerMaxShop:{
      configKeys: [
        { label: '商户秘钥', key: 'appSecret' },
        { label: '商户id', key: 'appId' },
        { label: '角色校验地址', key: 'cpCheckRoleUrl' },
        { label: '下单地址', key: 'cpToOrderUrl' },
        { label: '区服地址', key: 'cpGameServersUrl' },
        { label: 'usdk pcode', key: 'usdkPcode' },
        { label: 'usdk product_key', key: 'usdkProductKey' },
        { label: 'usdk callback_key', key: 'usdkCallbackKey' },
        {
          label: '商城接口地址',
          notRequired: true,
          key: 'callbackUrl',
          type: 'copy'
          // tips: '如需使用谷歌订阅功能，请将该订阅回调地址配置到谷歌后台。'
        },
        {
          label: '沙盒模式',
          key: 'sandbox',
          options: [{ label: '沙盒环境', value: 1 }, { label: '正式环境', value: 0 }],
          tips: '默认是沙盒环境'
        }
      ]
    }
  };

  GlobalPayConfigDict[1] = GlobalPayConfigDict.Apple;
  GlobalPayConfigDict[2] = GlobalPayConfigDict.Google;
  GlobalPayConfigDict[10] = GlobalPayConfigDict.Mycard10;
  GlobalPayConfigDict[11] = GlobalPayConfigDict.HuaWei;
  GlobalPayConfigDict[12] = GlobalPayConfigDict.Vivo;
  GlobalPayConfigDict[13] = GlobalPayConfigDict.OneStore;
  GlobalPayConfigDict[14] = GlobalPayConfigDict.OPPO;
  GlobalPayConfigDict[15] = GlobalPayConfigDict.MidEast;
  GlobalPayConfigDict[17] = GlobalPayConfigDict.XSOLLA;
  GlobalPayConfigDict[18] = GlobalPayConfigDict.XSOLLAPayment;
  GlobalPayConfigDict[19] = GlobalPayConfigDict.Payssion;
  GlobalPayConfigDict[21] = GlobalPayConfigDict.Airwallex;
  GlobalPayConfigDict[27] = GlobalPayConfigDict.Catappult;
  GlobalPayConfigDict[28] = GlobalPayConfigDict.Paypal;
  GlobalPayConfigDict[30] = GlobalPayConfigDict.PayerMax;
  GlobalPayConfigDict[31] = GlobalPayConfigDict.PayerMaxShop;

  const loginType = function (params: any, type = 'internal') {
    if (!params) return [];
    let arr: any[] = [];
    if (type === 'internal') {
      internalLogin.forEach(t => {
        if (params[t.type]) {
          arr.push(loginIcon[t.type as keyof typeof loginIcon]);
        }
      });
    } else if (type === 'global') {
      globalLogin.forEach(t => {
        if (params[t.key] && params[t.key].val == 1) {
          arr.push(globalLoginIcon[t.key as keyof typeof globalLoginIcon]);
        }
      });
    }
    return arr;
  };
  const max = Math.max(...Object.keys(globalPayIcon).map(n => Number(n)));
  const payType = function (params: any, type = 'internal') {
    if (!params) return [];
    let arr = [];
    if (type === 'internal') {
      internalPay.forEach(t => {
        if (params['type' + t.type]) {
          arr.push(payIcon[t.icon as keyof typeof payIcon]);
        }
      });
    } else if (type === 'global') {
      for (let i = 1; i < max + 1; i++) {
        if (params['type' + i] == 1) {
          arr.push(globalPayIcon[i as keyof typeof globalPayIcon]);
        }
      }
    }
    return arr;
  };
  return {
    loginType,
    payType,
    internalLogin,
    internalPay,
    globalLogin,
    globalPay,
    globalPayIcon,
    globalLoginIcon,
    loginIcon,
    payIcon,
    GlobalLoginConfigDict,
    GlobalPayConfigDict,
    internalLoginParamsChannel
  };
}
